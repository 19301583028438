@import 'mixins';
@import '../layoutVariables';

cg-chat {
  & .cg-chat-tab {
    & .cg-chat-type-icon {
      vertical-align: middle;
    }
  }
}

cg-chat {
  & cg-chat-tab {
    .cg-chat-prevent-text-selection;
    display: block;
    position: fixed;
    top: @cgChatOfferTop;
    right: -@cgChatTabWidth - @defaultMargin;
    width: @cgChatTabWidth;
    height: @cgChatTabHeight;
    transition: right 0.55s ease-out;
    overflow: hidden;
    pointer-events: none;
    background-color: @cgChatTabBG;
    z-index: @chatTabZIndex;
    border-radius: 15px 15px 0px 15px;
  }

  &.cg-chat-tab-visible cg-chat-tab {
    right: 0;
    cursor: pointer;
    pointer-events: all;
    border-radius: 15px 15px 0px 15px;
  }

  & .closed {
    background-color: @cgChatTabClosedBG !important;
  }

  & .cg-chat-tab {
    width: @cgChatTabHeight;
    height: @cgChatTabWidth;
    line-height: 45px;
    text-align: center;
    font-weight: 300;
    font-size: @fontSizeHeader;
    color: @cgChatTabTextColor;
    transform: rotate(-90deg) translate(-@cgChatTabTranslationX, -@cgChatTabTranslationY);
    cursor: pointer;

    & .cg-chat-type-icon {
      width: 30px;
      height: 30px;
      margin-left: -14px;
    }

    &.cg-chat-tab-top-down-text {
      position: relative;
      transform: translate(-@cgChatTabTranslationX, @cgChatTabTranslationY*0.7);

      // Queue ticket main shape
      &:before {
        content: '';
        position: absolute;
        top: ~'calc(50% - ' @cgChatQueuePositionHeight / 2 + 2px ~')';
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: @cgChatQueuePositionWidth;
        height: @cgChatQueuePositionHeight;
        border: 2px solid @cgChatTabTextColor;
        border-radius: 5px;
      }

      // Queue ticket hole
      &:after {
        content: '';
        position: absolute;
        top: ~'calc(50% - ' @cgChatQueuePositionHeight / 2 + 13px ~')';
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        background-color: @cgChatTabBG;
        width: 15px;
        height: 15px;
        border: 2px solid @cgChatTabTextColor;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 10px;
      }
    }
  }

  & cg-chat-tab.is-video-chat {
    color: @cgVideoChatTabTextColor;
    background-color: @cgVideoChatTabBG;
  }

  &.cg-chat-user-is-on-mobile {
    @media (max-width: 480px) {
      & cg-chat-tab {
        width: @cgChatTabWidthMobile;
        height: @cgChatTabHeightMobile;
      }

      & .cg-chat-tab {
        width: @cgChatTabHeightMobile;
        height: @cgChatTabWidthMobile;
        line-height: @cgChatTabWidthMobile + 4px;
        font-size: @fontSizeHeaderMobile;
        transform: rotate(-90deg) translate(-@cgChatTabTranslationXMobile, -@cgChatTabTranslationYMobile);

        &.cg-chat-tab-top-down-text {
          transform: translate(-@cgChatTabTranslationXMobile, @cgChatTabTranslationYMobile*0.7);

          // Queue ticket main shape
          &:before {
            top: ~'calc(50% - ' @cgChatQueuePositionHeightMobile / 2 ~')';
            width: @cgChatQueuePositionWidthMobile;
            height: @cgChatQueuePositionHeightMobile;
          }

          // Queue ticket hole
          &:after {
            width: 15px;
            height: 15px;
            top: ~'calc(50% - ' @cgChatQueuePositionHeightMobile / 2 + 11px ~')';
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
}
