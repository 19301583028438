@import '../../layoutVariables.less';

cg-chat {
  & cg-chat-system-message {
    position: absolute;
    top: 50px - 1px;
    display: block;
    z-index: @chatViewSystemMessageZIndex;
    box-sizing: border-box;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: keep-all;
    padding: @inputPadding;
    width: 100%;
    background-color: white;
    border: 1px solid @cgChatViewBorderColor;
    transition: height 0.5s;
    box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.7);

    &.cg-chat-folded {
      animation: cg-chat-system-message-folded 1.6s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
    }

    &.cg-chat-folded-fast {
      animation: cg-chat-system-message-folded 0s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
      display: none;
    }

    & .cg-chat-system-message-button {
      font-size: 16px;
      font-weight: bold;
      line-height: @defaultElementHeight - (@elementPadding * 2);
    }
  }
}

@keyframes cg-chat-system-message-folded {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}
