@import '../layoutVariables.less';

cg-chat {
  & cg-chat-offer {
    display: block;
    position: fixed;
    top: @cgChatOfferTop;
    right: -@cgChatOfferWidth;
    transition: right 0.55s ease-out;
    z-index: @chatOfferPanelZIndex;

    & input[type='checkbox'] {
      height: auto;
      width: auto;
    }

    & > .cg-chat-offer-panel {
      box-sizing: border-box;
      width: @cgChatOfferWidth;
      border: @inputBorder;
      border-top: none;
      background-color: @mainBGColor;

      & .cg-chat-offer-form-closed {
        display: block;
        position: relative;
        box-sizing: border-box;
        height: 100%;
        min-height: @cgChatOfferFormMinHeight;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 @inputPadding;
        margin-top: @windowHandleHeight;
        padding-top: @inputPadding;
        margin-bottom: @defaultMargin;
        z-index: @chatOfferFormZIndex;
      }

      & .cg-chat-form-closed-contact {
        margin-top: 30px;
        width: 70%;
        margin-left: 15%;

        button {
          margin-bottom: 20px;
          background-color: #333333;
          color: #ffffff;
          font-weight: 100;
        }
      }

      & > .cg-chat-window-handle {
        cursor: pointer;
        border: none;

        & > .cg-chat-window-handle-buttons {
          padding-right: 10px;

          & > button.cg-chat-window-handle-buttons-fold-in {
            pointer-events: auto;
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    &:before {
      content: '';
      width: @inputPadding;
      height: @cgChatTabHeight;
      position: absolute;
      top: 0;
      right: -@inputPadding;
      background-color: @cgChatOfferSideGlueBG;
    }
  }

  @media (max-height: 768px) {
    cg-chat-offer {
      top: 0px;
    }
  }

  &.cg-chat-tab-hidden cg-chat-offer {
    transition: right 0.55s ease-in;
    right: 0;
    border-top-left-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.33);
  }

  &.cg-chat-user-is-on-mobile {
    &.cg-chat-tab-hidden cg-chat-offer {
      right: 0;
    }

    & cg-chat-offer {
      top: 0;
      right: -100%;
      width: 100%;
      height: 100%;

      box-sizing: border-box;
      background-color: @mainBGColor;

      & > .cg-chat-offer-panel {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.cg-chat-is-sub-window-chat {
    & cg-chat-offer {
      & .cg-chat-window-input-send {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
      }
    }
  }
}
