@import '../layoutVariables.less';

cg-chat {
  & .cg-video-chat-window {
    display: block;
    position: fixed;
    width: @cgVideoChatViewWidth;
    height: @cgVideoChatViewHeight;
    bottom: @cgVideoChatViewStartPositionBottom;
    right: @cgVideoChatViewStartPositionRight;
    background-color: @mainBGColor;
    margin: 0;
    padding: 0;
    overflow: hidden;
    // transition: opacity 0.35s;
  }

  & cg-video-chat-offer {
    opacity: 0.9;
  }

  &.cg-video-chat-is-sub-window-chat .cg-video-chat-window {
    height: 100%;
    width: 100%;
    min-width: @cgChatViewWidth;
    top: 0;
    left: 0;
    right: initial;
    bottom: initial;
  }

  &.cg-video-chat-user-is-on-mobile {
    & .cg-video-chat-window {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      // transition: opacity 0.35s;
    }

    & .cg-video-chat-window-content {
      box-sizing: border-box;
    }

    &.cg-video-chat-is-sub-window-chat .cg-video-chat-window {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-width: @cgChatViewWidth;
    }
  }
}
