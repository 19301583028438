@import '../layoutVariables.less';

cg-chat {
  & .cg-chat-window {
    display: block;
    position: fixed;
    width: @cgChatViewWidth;
    height: @cgChatViewHeight;
    bottom: @cgChatViewStartPositionBottom;
    right: @cgChatViewStartPositionRight;
    background-color: @mainBGColor;
    margin: 0;
    padding: 0;
    overflow: hidden;
    // transition: opacity 0.35s;
    z-index: @chatViewZIndex;
    border-top-left-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.33);
  }

  // Border radius isn't necessary when the
  // chat in opened in a new window
  &.cg-chat-is-sub-window-chat .cg-chat-window {
    border-top-left-radius: 0;
  }

  & .cg-chat-window-content {
    box-sizing: border-box;
  }

  &.cg-chat-is-sub-window-chat {
    .cg-chat-window {
      height: 100%;
      width: 100% !important;
      min-width: @cgChatViewWidth;
      top: 0;
      left: 0;
      right: initial;
      bottom: initial;
    }
  }

  &.cg-chat-user-is-on-mobile {
    .cg-chat-window {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      // transition: opacity 0.35s;
    }

    & .cg-chat-window-content {
      box-sizing: border-box;
    }
  }
}
