@import '../../layoutVariables.less';

cg-chat {
  & .cg-video-chat-offer-view {
    position: absolute;
    top: @windowHandleHeight;
    box-sizing: border-box;
    height: @cgChatViewHeight - @windowHandleHeight;
    padding: @inputPadding;
    background-color: @TeliaDarkGray90;
    color: white;
    display: block;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  & #video-chat-offer-heading {
    border: none;
    padding: 50px 30px 20px 30px;
    display: block;
    line-height: 1.5em;
    font-weight: bold;
  }

  & .video-chat-offer-button-area {
    border: none;
    padding: 15px 5px 15px 5px;
    display: block;
    width: 80%;
    margin: auto;
    overflow: auto;
    background-color: @TeliaDarkGray;
    border-radius: 3px;
  }

  & .video-chat-offer-decline-area {
    padding-left: 25px;
    display: inline-table;
    float: left;
  }

  & .video-chat-offer-accept-area {
    padding-right: 25px;
    display: inline-table;
    float: right;
  }

  & .video-chat-offer-buttons-heading {
    border: none;
    padding-bottom: 16px;
    display: block;
  }

  & .video-chat-offer-button {
    border: none;
    width: 54px;
    min-width: 54px;
    max-width: 54px;
    height: 54px;
    min-height: 54px;
    max-height: 54px;
    text-align: center;
    border-radius: 27px;

    img {
      vertical-align: middle;
      width: 28px;
      height: 28px;
    }
  }

  & #video-chat-offer-decline-button {
    background-color: #b2012c;
    border: none;

    &:hover {
      background-color: #a00128;
    }

    &:active {
      background-color: #8e0123;
    }
  }

  & #video-chat-offer-accept-button {
    background-color: #018945;
    border: none;

    &:hover {
      background-color: #017b3e;
    }

    &:active {
      background-color: #016e37;
    }
  }

  &.cg-chat-is-sub-window-chat {
    .cg-video-chat-offer-view {
      height: 100%;
    }
  }
}
