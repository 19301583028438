@import '../../layoutVariables.less';

cg-chat {
  & .email-offer-log {
    height: ~'calc(100% - ' @windowHandleHeight ~')';
  }

  & .cg-chat-email-offer-form {
    box-sizing: border-box;
    overflow: hidden;
    height: 0;
    transition: height 0.5s ease-in-out;

    & > div {
      box-sizing: border-box;
      height: 100%;

      & > input,
      & > button {
        margin-bottom: @defaultMargin;
        height: @defaultElementHeight;
        max-height: @defaultElementHeight;
        min-height: @defaultElementHeight;
      }
    }
  }
  & .cg-chat-email-offer-button {
    background: @inputButtonColor;
    color: @inputButtonTextColor;
    line-height: 36px;
    height: 36px;
    padding-top: 0;
    padding-bottom: 0;

    &:focus,
    &:hover {
      background: @inputButtonColor;
      color: @inputButtonTextColor;
      outline: none;
    }
  }

  & .cg-chat-email-offer-input-group {
    display: flex;
    border: @inputBorder;
    border-radius: 3px 3px 3px 3px;

    & > input {
      min-width: ~'calc(100% - ' @defaultElementHeight ~')';
      width: ~'calc(100% - ' @defaultElementHeight ~')';
      max-width: ~'calc(100% - ' @defaultElementHeight ~')';
      border: none;
    }

    & > button {
      width: @defaultElementHeight;
      min-width: @defaultElementHeight;
      max-width: @defaultElementHeight;
      padding: 0;
      border: none;

      img {
        background: @inputButtonColor;
        border-radius: 3px;
      }
    }
  }

  & .cg-chat-email-offer-done,
  & .cg-chat-email-offer-over {
    color: @emailOfferDoneColor;
    width: 100%;
    text-align: center;
    line-height: 2 * @defaultElementHeight + @inputPadding;
  }

  & .cg-chat-email-offer-over {
    color: @defaultTextColor;
  }

  cg-chat-email-offer {
    display: block;
    position: relative;
    padding-left: @inputPadding;
    padding-right: @inputPadding;

    &.cg-chat-email-offer-show .cg-chat-email-offer-form {
      height: @defaultElementHeight + @inputPadding + @defaultMargin;
      margin-top: @defaultMargin;
    }

    &.cg-chat-email-offer-show:after {
      content: '';
      display: block;
      width: 100%;
      height: @defaultElementHeight + @inputPadding + @defaultMargin;
      animation: cg-chat-remove-height 0.5s ease-in-out forwards;
    }
  }
}

@keyframes cg-chat-remove-height {
  100% {
    height: 0;
  }
}
