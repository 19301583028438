@import '../../layoutVariables.less';

cg-chat {
  & .cg-video-chat-view {
    position: absolute;
    top: @windowHandleHeight;
    box-sizing: border-box;
    height: @cgChatViewHeight - @windowHandleHeight;
    padding: 0;
    background-color: @TeliaDarkGray90;
    width: 100%;
    margin: auto;
  }

  & .cg-video-chat-suspended-video {
    position: absolute;
    top: @windowHandleHeight;
    box-sizing: border-box;
    height: @cgChatViewHeight - @windowHandleHeight;
    padding: 0;
    background-color: red;
    width: 100%;
    margin: auto;
    z-index: 1500;
  }

  & #cg-video-chat-move-video-here-button {
    border: none;
    padding: 1px;
    background-color: #d0021b;
  }

  & #cg-video-chat-move-video-here-button img {
    vertical-align: middle;
  }

  & .cg-video-chat-video-panel {
    display: block;
    position: relative;
    width: 93%;
    height: 77%;
    padding: 0;
    margin: auto;
    top: 23px;
  }

  & #cg-video-chat-video-panel-subscriber-main {
    width: 100%;
    height: 100%;
    float: left;
    top: 0;
    z-index: 1030 !important;
  }

  & #cg-video-chat-video-panel-subscriber-1 {
    position: absolute;
    width: 50%;
    height: 28%;
    left: 0;
    bottom: 0;
    z-index: 1035 !important;
    border: none;
  }

  & #cg-video-chat-video-panel-subscriber-2 {
    position: absolute;
    width: 50%;
    height: 28%;
    left: 0;
    top: 0;
    z-index: 1035 !important;
    border: none;
  }

  & #cg-video-chat-video-panel-subscriber-3 {
    position: absolute;
    width: 50%;
    height: 28%;
    right: 0;
    top: 0;
    z-index: 1035 !important;
    border: none;
  }

  & .cg-video-placeholder {
    border: 1px solid white;
    background-color: #222;
    background-position: center;
    background-repeat: no-repeat;
  }

  & #cg-video-chat-video-panel-publisher {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1035 !important;
    width: 50%;
    height: 28%;
  }

  & .cg-video-chat-control-panel {
    width: 100%;
    height: 14%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0;
    margin: auto;
    background-color: #222222;
  }
}

.cg-chat-user-is-on-mobile {
  .cg-video-chat-view {
    height: ~'calc(100% - ' @windowHandleHeight ~');';
  }
}

.cg-chat-is-sub-window-chat {
  .cg-video-chat-view {
    height: ~'calc(100% - ' @windowHandleHeight ~');';
  }
}

.cg-chat-user-is-on-mobile {
  & .cg-video-chat-video-panel {
    @media (orientation: landscape) {
      width: 55%;
      height: 77%;

      #cg-video-chat-video-panel-publisher,
      #cg-video-chat-video-panel-subscriber-1,
      #cg-video-chat-video-panel-subscriber-2,
      #cg-video-chat-video-panel-subscriber-3 {
        width: 37%;
        height: 28%;
      }
    }
  }
}

.cg-chat-user-is-on-mobile,
.cg-chat-is-sub-window-chat {
  #cg-video-chat-video-panel-publisher,
  #cg-video-chat-video-panel-subscriber-1,
  #cg-video-chat-video-panel-subscriber-2,
  #cg-video-chat-video-panel-subscriber-3 {
    width: 50%;
    height: 28%;
    /* Device = Desktops, Screen = 1281px to higher resolution desktops, Use ration 4:3 */
    @media (min-width: 1281px) {
      width: 33%;
      height: 37%;
    }

    /* Device = Laptops, Desktops, Screen = B/w 1025px to 1280px, Use ration 4:3 */
    @media (min-width: 1025px) and (max-width: 1280px) {
      width: 50%;
      height: 28%;
    }

    /* Device = Tablets, Ipads (portrait), Screen = B/w 768px to 1024px, Use ration 3:4 */
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 36%;
      height: 28%;
    }

    ///* Device = Tablets, Ipads (landscape), Screen = B/w 768px to 1024px, Use ration 4:3 */
    //@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //  width: 50%;
    //  height: 28%;
    //}

    /* Device = Laptops, Desktops, Screen = B/w 1025px to 1280px, Use ration 4:3 */
    @media (min-width: 1025px) and (max-width: 1280px) {
      width: 33%;
      height: 37%;
    }

    /* Device = Low Resolution Tablets, Mobiles (Landscape), Screen = B/w 481px to 767px, Use ration 4:3 */
    @media (min-width: 481px) and (max-width: 767px) {
      width: 50%;
      height: 28%;
    }

    /* Device = Most of the Smartphones Mobiles (Portrait), Screen = B/w 320px to 479px, Use ration 3:4 */
    @media (min-width: 320px) and (max-width: 480px) {
      width: 37%;
      height: 37%;
    }
  }
}
