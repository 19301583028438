@import '../layoutVariables.less';
@import './mixins.less';

/*
 * Sets default styles to some base elements in cg-chat element
 */

cg-chat {
  & *:after,
  & *:before {
    box-sizing: content-box;
  }

  /* fix for ALM-18459: checkbox can not have a set width and height in Mac Safari */

  & input:not([type='checkbox']),
  & button,
  & select,
  & textarea {
    box-sizing: border-box;
    display: inline-block;
    font-family: 'Helvetica';
    font-size: @defaultFontSize;
    color: @defaultTextColor;
    font-weight: normal;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    height: @defaultElementHeight;
    padding: @elementPadding;
    margin: 0;
    background: none;
    background-color: @inputColor;
    border: @inputBorder;
    border-radius: 3px 3px 3px 3px;

    &:focus,
    &:hover {
      background: none;
      background-color: @inputColor;
      border: @inputBorder;
    }

    // transition: background-color 0.5s ease-in-out;

    &.cg-chat-input-flash,
    &:focus.cg-chat-input-flash,
    &:hover.cg-chat-input-flash {
      background-color: @flashColor;
    }

    &.cg-chat-input-error {
      background-color: @errorBackgroundColor;
      border-top-color: @errorBorderColor !important;

      &::placeholder {
        color: @errorBorderColor;
      }

      &:not(textarea) {
        border-color: @errorBorderColor !important;
      }

      & + .cg-chat-input-error-label {
        display: block;
      }
    }
  }

  & .cg-chat-input-error-label {
    background-color: @errorBorderColor;
    color: white;
    position: absolute;
    bottom: 100%;
    right: 20px;
    font-size: 12px;
    padding: 3px 6px 2px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: none;
  }

  /* fix for ALM-18459: checkbox can not have a set width and height in Mac Safari */

  & input[type='checkbox'] {
    padding: @elementPadding;

    box-sizing: border-box;
    display: inline-block;
    font-family: 'Helvetica';
    font-size: @defaultFontSize;
    color: @defaultTextColor;
    font-weight: normal;
    margin: 0;
    background: none;
    background-color: @inputColor;
    border: @inputBorder;
    border-radius: 3px 3px 3px 3px;

    &:focus,
    &:hover {
      background: none;
      background-color: @inputColor;
      border: @inputBorder;
    }

    // transition: background-color 0.5s ease-in-out;

    &.cg-chat-input-flash,
    &:focus.cg-chat-input-flash,
    &:hover.cg-chat-input-flash {
      background-color: @flashColor;
    }
  }

  & textarea::placeholder,
  & input::placeholder {
    color: @placeholderTextColor;
  }

  & input,
  & select {
    min-height: @defaultElementHeight;
    max-height: @defaultElementHeight;
  }

  & button {
    overflow: hidden;
    line-height: 25px;
    cursor: pointer;
    letter-spacing: normal;
    text-align: center;

    &:active img {
      opacity: 1;
    }
  }

  & select {
    color: @placeholderTextColor;
    .cg-chat-prevent-text-selection;
    appearance: none;
    text-indent: 1px;
    text-overflow: '';

    &::-ms-expand {
      display: none;
    }

    &:focus::-ms-value {
      color: @defaultTextColor;
      background-color: @inputColor;
    }

    & option {
      .cg-chat-prevent-text-selection;
    }
  }

  & div {
    line-height: normal;
    padding: 0;
    margin: 0;
  }

  & svg,
  & img {
    display: inline-block;
  }

  & .cg-chat-cloaked {
    opacity: 0 !important;
  }

  & .cg-chat-hidden {
    display: none !important;
  }

  & .cg-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    height: 100%;

    button {
      all: unset;
      box-sizing: border-box;
      border: 0;
      color: white;
      font-family: sans-serif;
      padding: 8px 16px;
    }

    button.icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }

    .icon {
      border-radius: 50%;
      height: 42px;
      width: 42px;
    }

    .icon.text {
      border-radius: 21px;
      min-height: 42px;
      width: unset;
      padding: 8px 16px;
    }

    .icon svg {
      height: 18px;
    }

    .icon.text svg {
      padding-right: 12px;
    }

    .text {
      border-radius: 21px;
      min-height: 42px;
      min-width: 96px;
      width: unset;
      padding: 0 16px;
      font-size: 16px;
    }

    .ok {
      background-color: #666666;
    }

    .ok:hover {
      background-color: #5b5b5b;
    }

    .ok:active {
      background-color: #474747;
    }

    .ok.status {
      color: white;
      background-color: #018945;
    }

    .ok.status:hover {
      color: white;
      background-color: #017b3e;
    }

    .ok.status:active {
      color: white;
      background-color: #016e37;
    }

    .cancel {
      background-color: #b2012c;
    }

    .cancel:hover {
      background-color: #a00128;
    }

    .cancel:active {
      background-color: #8e0123;
    }

    .on {
      color: black;
      background-color: #ffffff;
    }

    .on:hover {
      color: black;
      background-color: #e5e5e5;
    }

    .on:active {
      color: black;
      background-color: #cccccc;
    }
  }
}
