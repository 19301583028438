@import '../layoutVariables.less';

cg-chat {
  & .cg-container {
    margin: 16px;
  }

  & .cg-video-status {
    position: absolute;
    top: @windowHandleHeight;
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    background-color: @TeliaDarkGray90;
    width: 100%;
    margin: auto;
    color: white;
    display: block;
    text-align: center;
    z-index: 1060;

    & .cg-bottom {
      margin-top: 32px;
    }

    & a:link {
      color: white;
    }

    & a:visited {
      color: white;
    }

    & a:hover {
      color: #ffcc00;
    }

    & #cg-video-status-heading {
      font-weight: bold;
      font-size: 18px;
      padding-top: 10px;
    }

    & #cg-video-status-text {
      font-weight: normal;
    }

    & #cg-video-status-link-container {
      margin-bottom: 48px; // Avoid link being near Cancel button!
    }
  }
}
