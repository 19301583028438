@import '../layoutVariables.less';
@import 'mixins.less';

cg-chat {
  & .cg-chat-window-input-area {
    & .cg-chat-request-video-chat {
      padding: 12px @inputPadding 16px;

      & > div {
        display: inline;
        line-height: 10px;
        vertical-align: middle;
        margin: 130px;
      }
    }

    & .cg-chat-request-video-chat label {
      display: inline;
      vertical-align: middle;
    }

    & .cg-chat-request-video-chat input {
      display: inline-block;
      vertical-align: middle;
      border-radius: 0;
      appearance: none;
      -webkit-appearance: none;
      width: 16px;
      min-width: 16px;
      height: 16px;
      min-height: 16px;

      &:checked {
        background-color: @inputButtonColor;
      }
    }
  }

  & .cg-chat-log-is-resizing {
    .cg-chat-prevent-text-selection;
  }

  & .cg-chat-window-input-area {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    vertical-align: middle;
    width: 100%;
    background-color: @inputColor;

    &.cg-chat-resizable {
      & > .cg-chat-resize-handle {
        position: absolute;
        left: 0;
        top: -8px;
        width: 100%;
        height: 8px;
        opacity: 0.4;
        cursor: ns-resize;
        z-index: @chatViewTextBoxZIndex;
        border-bottom: 1px solid @cgChatOfferBorderColor;
      }
    }

    & .cg-chat-window-input-text-area {
      border: none;
      border-radius: 0;
      box-sizing: border-box;
      width: ~'calc(100% - ' @cgChatViewSendButtonWidth ~')';
      max-width: ~'calc(100% - ' @cgChatViewSendButtonWidth ~')';
      border-bottom: 1px solid @cgChatOfferBorderColor;
      border-top: 1px solid @cgChatOfferBorderColor;
      height: 68px;
      resize: none;
      padding: @inputPadding;
      display: inline-block;
      vertical-align: middle;
      position: relative;

      &:disabled {
        opacity: 0.3;
      }

      &:focus {
        outline: none;
      }
    }

    .text-area-chat-view {
      float: left;
      min-width: ~'calc(100% - ' @cgChatViewSendButtonWidth ~')';
      border-top: none !important;
    }

    & .cg-chat-window-input-send {
      box-sizing: border-box;
      background-color: @inputButtonColor;
      text-align: center;
      line-height: 0;
      border: none;
      cursor: pointer;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 36px;
      min-height: 36px;
      max-height: 36px;
      color: @inputButtonTextColor !important;
      min-width: ~'calc(100% - ' 30px ~')';
      width: ~'calc(100% - ' 30px ~')';
      max-width: ~'calc(100% - ' 30px ~')';
      min-width: ~'calc(100% - ' 30px ~')';
      margin-left: 15px;
      margin-bottom: 4px;

      &:disabled {
        cursor: default;

        &:active img {
          opacity: 1;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: @inputColor;
          opacity: 0.7;
        }
      }
    }

    .button-chat-view {
      color: @inputButtonColor !important;
      width: @cgChatViewSendButtonWidth;
      min-width: @cgChatViewSendButtonWidth;
      max-width: @cgChatViewSendButtonWidth;
      float: left;
      margin: 0;
      background: none;
      height: 68px;
      min-height: 68px;
      max-height: 68px;

      img {
        background: @inputButtonColor;
        border-radius: 3px;
      }
    }
  }
}
