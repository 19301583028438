@import '../../layoutVariables.less';
@import '../../uiElements/mixins.less';

cg-chat {
  & .cg-chat-knowledge-guide {
    overflow: unset;
    position: relative;
    margin-bottom: @defaultMargin;
    padding: 0 @inputPadding @elementPadding;
    margin: 0px;
  }

  & .cg-chat-knowledge-guide button {
    height: 100%;
    box-shadow: 2px 2px 2px #c8e4eb;
    border-radius: 18px;
    border: 1px solid @cgChatMainColor;
    color: @cgChatMainColor;
    padding: 10px;
    margin: 0px;
  }

  & .cg-chat-knowledge-guide-button-left {
    float: left;
  }

  & .cg-chat-knowledge-guide-button-right span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 15px;
  }

  & .cg-chat-knowledge-guide-button-right p {
    &:extend(.cg-chat-word-break all);

    color: @cgChatMainColor;
    font-size: 1em;
    text-align: left;
    width: 100%;
  }
}
