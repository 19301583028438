/*
 * Some helper mixins.
 */

@import '../layoutVariables.less';

// Creates the handle in the chat offer and the chat view
.window-handle() {
  width: 100%;
  box-sizing: border-box;
  height: @windowHandleHeight;
  line-height: @windowHandleHeight;
  position: absolute;
  top: 0;
  left: 0;
  background-color: @windowHandleColor;
  color: #fff;
  font-size: 18px;
  padding-left: 12px;
  cursor: move;
  z-index: 10;
}

// Puts a little whale tail right of messages
.tailRight(@color, @bgcolor) {
  background-color: @color;
}

.cg-chat-prevent-text-selection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* non-prefixed version, currently
                                  not supported by any browser */
}

.cg-chat-word-break {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
