@import '../../layoutVariables.less';

cg-chat {
  & .cg-chat-window-message-log {
    position: absolute;
    top: @windowHandleHeight;
    width: 100%;
    z-index: @chatViewLogZIndex;
    box-sizing: border-box;
    height: @cgChatViewHeight - @windowHandleHeight - @cgChatViewInputAreaHeight;
    // border: 1px solid , @inputBorder;
    border-bottom: none;

    padding-top: @inputPadding;
    background-color: @mainBGColor;

    & cg-chat-email-offer {
      padding-top: @defaultPadding;
      padding-bottom: @defaultPadding;
    }
  }

  &.cg-chat-is-sub-window-chat .cg-chat-window-message-log,
  &.cg-chat-user-is-on-mobile .cg-chat-window-message-log {
    height: ~'calc(100% - ' (@windowHandleHeight + @cgChatViewInputAreaHeight) ~')';
  }
}
