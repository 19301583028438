@import '../../layoutVariables.less';
@import '../../uiElements/mixins.less';

cg-chat {
  & .cg-chat-message {
    overflow: auto;
    position: relative;
    margin-bottom: 8px;
    padding: 0 @inputPadding 8px;
    &:last-child {
      margin-bottom: 0;
    }
    &:extend(.cg-chat-word-break all);
  }

  & .cg-chat-system-message {
    &:extend(.cg-chat-word-break all);

    & .cg-chat-system-message-text {
      line-height: @defaultFontSize + 4px;
      font-size: @systemMessageFontSize;
      color: @systemMessageTextColor;
      text-align: center;
    }
  }

  & .cg-chat-system-message-alias {
    font-size: @aliasFontSize;
  }

  & .cg-chat-system-message-timestamp {
    font-size: @timestampFontSize;
    text-align: center;
    color: @systemMessageTextColor;
  }

  & .cg-chat-agent-message-not-open {
    box-sizing: content-box;
    position: relative;
    padding: @inputPadding @inputPadding 50px @inputPadding;
    z-index: @chatViewMsgZIndex;
    background-color: #dce4eb;
    border-radius: 0px 0px 30px 0px;

    & .cg-chat-agent-message-text {
      &:extend(.cg-chat-word-break all);
      white-space: pre-wrap;
      font-size: @defaultFontSize;

      & div {
        font-weight: 100;
      }
    }
  }

  & #cg-chat-open-hours {
    &:extend(.cg-chat-word-break all);
    white-space: pre-wrap;
    font-size: @defaultFontSize;

    table {
      margin-left: 12%;
    }

    h1 {
      font-size: 1.5em;
      text-align: center;
    }

    div {
      font-weight: 100;
    }

    td {
      padding: 4px;
      padding-left: 30px;
    }
  }

  & .cg-chat-agent-message,
  & .cg-chat-customer-message {
    box-sizing: content-box;
    max-width: ~'calc(100% - ' @cgchatMessagePaddingOppositeSide ~')';
    display: inline-block;
    position: relative;
    padding: @defaultMargin;
    z-index: @chatViewMsgZIndex;

    &.cg-chat-message-has-alias {
      margin-top: @aliasFontSize + 4px;
    }

    &.cg-chat-message-has-timestamp {
    }

    & .cg-chat-agent-message-text,
    & .cg-chat-customer-message-text {
      &:extend(.cg-chat-word-break all);
      white-space: pre-wrap;
      font-size: @defaultFontSize;
    }
  }

  .cg-chat-agent-avatar {
    vertical-align: bottom;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: @chatViewMsgAvatarZIndex;
    padding-left: @elementPadding;

    & img {
      height: @cgChatAvatarHeight + 2px;
      width: @cgChatAvatarWidth + 2px;
    }
  }

  & .cg-chat-agent-message {
    float: left;
    color: @agentTextColor;
    border-radius: 20px 20px 20px 0px;
    background-color: @agentColor;
  }

  & .cg-chat-agent-message-alias {
    margin-top: -(@defaultMargin + @aliasFontSize + 4px);
    margin-bottom: @defaultMargin + 2px;
    color: @aliasTextColor;
    margin-left: -@inputPadding + 2px;
    font-size: @aliasFontSize;
  }

  & .cg-chat-customer-message {
    float: right;
    color: @customerTextColor;
    border-radius: 20px 20px 0px 20px;
    background-color: @customerColor;
  }

  & .cg-chat-customer-message-alias {
    margin-top: -(@defaultMargin + @aliasFontSize + 4px);
    margin-bottom: @defaultMargin + 2px;
    color: @aliasTextColor;
    margin-left: -@inputPadding + 2px;
    font-size: @aliasFontSize;
  }

  & .cg-chat-agent-message-timestamp,
  & .cg-chat-customer-message-timestamp {
    font-size: @timestampFontSize;
    padding-top: @elementPadding;
    float: right;
  }
}
