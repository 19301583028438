@import '../../layoutVariables.less';

cg-chat {
  & cg-chat-offer-form {
    display: block;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    min-height: @cgChatOfferFormMinHeight;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    //padding: 0 @inputPadding;
    margin-top: @windowHandleHeight;
    padding-top: @inputPadding;
    margin-bottom: @defaultMargin;
    z-index: @chatOfferFormZIndex;

    & .hidden {
      display: none;
    }

    & .cg-chat-agent-message {
      max-width: 100%;
      // ~"calc(100% - " @cgchatOfferMessagePaddingOppositeSide ~")";
      margin-top: 0;

      &.cg-chat-message-has-avatar {
        max-width: ~'calc(100% - ' @cgchatOfferMessagePaddingOppositeSide ~' - ' @cgChatAvatarWidth ~')';
      }
    }

    & .cg-chat-required-field-message-area {
      position: relative;
      margin-bottom: @defaultMargin + 2;
      padding: 0 @inputPadding;
      &::before {
        content: '';
        position: absolute;
        top: -8px;
        right: 0;
        left: 0;
        height: 1px;
        background-color: @cgChatOfferBorderColor;
      }
      & .cg-chat-required-field-message {
        font-size: 90%;
        vertical-align: middle;
      }

      & .cg-chat-required-field-icon {
        float: left;
        margin-left: 0px;
        margin-right: 5px;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        background: @cgChatMainColor;
        border: 1px solid @cgChatMainColor;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        font-size: 70%;
      }
    }

    & .cg-chat-form-contact-data-wrapper {
      position: relative;
    }

    & .cg-chat-form-contact-data {
      margin-bottom: @defaultMargin - 2;
      padding: 0 @inputPadding;

      & .cg-chat-form-required-field-marker {
        padding: 0;
      }

      & > div {
        word-break: break-word;
        color: @inputLabelColor;
        font-weight: bold;
      }

      & .cg-chat-select-box {
        position: relative;

        & > .cg-chat-form-contact-data-wrapper > select {
          border: @inputSelectBorder;
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
        }

        // Uniform design on the right side "arrow down" button on the select box.
        & > span {
          display: block;
          position: absolute;
          top: ~'calc(50% - ' 6px ~')';
          right: 22px;
          text-align: center;
          box-sizing: border-box;
          cursor: default;
          pointer-events: none;
          width: 12px;
          height: 12px;
          fill: @inputSelectBoxArrowColor;
          background: transparent;
        }
      }
    }

    & .cg-chat-window-input-area {
      position: relative;
      bottom: inherit;
      width: 100%;
    }
  }

  &.cg-chat-user-is-on-mobile {
    & cg-chat-offer-form {
      height: ~'calc(100% - ' @windowHandleHeight ~');';
      padding: @defaultPadding;
    }
  }

  &.cg-chat-user-is-on-mobile {
    & .cg-video-chat-offer-view {
      width: 100vw;
      height: 100vh;
    }
  }
  &.cg-chat-is-sub-window-chat .cg-chat-form-contact-data {
    padding: 0;
  }
  &.cg-chat-is-sub-window-chat {
    & cg-chat-offer-form .cg-chat-message {
      padding: 0;
    }
  }
}
