@import '../../layoutVariables.less';

cg-chat {
  & .cg-chat-window-agent-pen-status {
    overflow: auto;
    position: relative;
    text-align: center;

    & span {
      position: relative;
      bottom: 0;
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation: cg-chat-pen-status-jumping-dots 1.5s linear infinite;

      &.cg-chat-pen-status-dot-1 {
        animation-delay: 200ms;
      }
      &.cg-chat-pen-status-dot-2 {
        animation-delay: 400ms;
      }
    }
  }
}

@keyframes cg-chat-pen-status-jumping-dots {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
}
