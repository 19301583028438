/*
 * All Layout variables used in the application
 */

/*
 * Sizes
 */

@defaultMargin: 10px;

@elementPadding: 6px;
@defaultPadding: 20px;
@inputPadding: 15px;

@defaultElementHeight: 30px;
@selectboxHandleWidth: 30px;

// Chat tab

@cgChatTabWidth: 50px;
@cgChatTabHeight: 160px;
@cgChatTabTranslationX: 50px;
@cgChatTabTranslationY: 50px;
@cgChatQueuePositionWidth: 40px;
@cgChatQueuePositionHeight: 40px;

@cgChatTabWidthMobile: 30px;
@cgChatTabHeightMobile: 125px;
@cgChatTabTranslationXMobile: 48px;
@cgChatTabTranslationYMobile: 48px;
@cgChatQueuePositionWidthMobile: 22px;
@cgChatQueuePositionHeightMobile: 28px;

@cgChatQueuePositionRadius: 5px;

// Chat offer

@cgChatOfferTop: 100px;
@cgChatOfferWidth: 300px;
@cgChatOfferFormMinHeight: 150px;
@cgchatOfferMessagePaddingOppositeSide: 70px;

// Window handle

@windowHandleHeight: 50px;

// Chat view

@cgChatViewStartPositionBottom: 0px;
@cgChatViewStartPositionRight: 40px;
@cgChatViewWidth: 320px; // 300px;
@cgChatViewHeight: 570px; // 450px;
@cgChatViewInputAreaHeight: 80px;
@cgChatViewSendButtonWidth: 60px;

// Both view and offer

@cgchatMessagePaddingOppositeSide: 65px;
@cgChatAvatarWidth: 30px;
@cgChatAvatarHeight: 32px; // Recommended max height is 60px

// Video chat view

@cgVideoChatViewStartPositionBottom: 0px;
@cgVideoChatViewStartPositionRight: 340px;
@cgVideoChatViewWidth: 320px;
@cgVideoChatViewHeight: 570px;

// Fonts

@timestampFontSize: 12px;
@aliasFontSize: 14px;
@defaultFontSize: 16px;
@systemMessageFontSize: 14px;
@fontSizeHeader: 20px;
@fontSizeHeaderMobile: 18px;

/*
 * Colors
 */

/*
 Main color that does not pass WCAG color contrast rule: #428BCA
 Main color that does pass WCAG AA color contrast rule: #3479B7
 Main color that does pass WCAG AAA color contrast rule: #2A5A8E
 */

@TeliaDarkGray: #333333;
@TeliaDarkGray90: #474747;

@cgChatMainColor: #428bca;

@mainBGColor: #fff;
@defaultTextColor: #4b4b4b;
@placeholderTextColor: #595959;
@flashColor: #f9dc00;
@linkColor: #224771;
@inputLabelColor: #343434;
@inputColor: #fff;
@inputBorder: 1px solid #d6d6d6;
@inputColorFocus: #d6d6d6;
@inputButtonColor: @cgChatMainColor;
@inputButtonTextColor: #fff;
@inputSelectBoxArrowColor: @cgChatMainColor;
@inputSelectBorder: 1px solid @cgChatMainColor;
@errorBackgroundColor: #fef3f6;
@errorBorderColor: #b30024;

// Chat tab

@cgChatTabBG: @cgChatMainColor;
@cgChatTabClosedBG: #7c99b2;
@cgVideoChatTabBG: @TeliaDarkGray;
@cgChatTabTextColor: #fff;
@cgVideoChatTabTextColor: #fff;

// Chat offer

@cgChatOfferSideGlueBG: #ccc;
@cgChatOfferBorderColor: #ececec;

// Chat view

@cgChatViewBorderColor: #979797;

// Email offer

@emailOfferIconHeight: 24px;
@emailOfferDoneColor: @cgChatMainColor;

// Window handle

@windowHandleColor: @cgChatMainColor;
@windowHandleTextColor: #fff;

@textChatWindowHandleColor: @cgChatMainColor;
@textChatWindowHandleTextColor: #fff;

@videoChatWindowHandleColor: @TeliaDarkGray;
@videoChatWindowHandleTextColor: #fff;

// Message bubbles

@agentColor: #e7e7e7;
@agentTextColor: @defaultTextColor;

@customerColor: @cgChatMainColor;
@customerTextColor: #fff;

@aliasTextColor: @defaultTextColor;

@systemMessageTextColor: #595959;

/*
 * Z-indices
 */

@baseZIndex: 1016;

@mainZIndex: @baseZIndex;
@chatTabZIndex: @baseZIndex;
@chatOfferPanelZIndex: @baseZIndex + 1;
@chatOfferFormZIndex: @baseZIndex;

@chatViewZIndex: @baseZIndex + 2;
@chatViewHandleZIndex: @baseZIndex + 10;
@chatViewSystemMessageZIndex: @baseZIndex + 5;

@chatViewLogZIndex: @baseZIndex;
@chatViewTextBoxZIndex: @baseZIndex + 1;

@chatViewMsgZIndex: @baseZIndex;
@chatViewMsgAvatarZIndex: @baseZIndex + 1;

/*
 * Sourcemap hack to get this file in the sourcemaps
 */
cg-chat {
  display: block;
}
