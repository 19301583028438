@import 'layoutVariables';

cg-chat {
  display: block;
  z-index: @mainZIndex;

  & {
    font-family: 'Helvetica';
    font-size: @defaultFontSize;
    line-height: normal;
    color: @defaultTextColor;
    box-sizing: content-box;

    #cg-chat-check-for-css-calc {
      width: 1px;
      width: ~'calc(1px + 1px)';
    }

    .cg-chat-screen-reader {
      text-indent: -5000px;
      position: absolute;
    }
  }
}

cg-chat-view {
  display: block;
}
