@import '../../layoutVariables.less';
@import '../../uiElements/mixins.less';

cg-chat {
  & .cg-chat-survey {
    overflow: auto;
    position: relative;
    margin-bottom: @defaultMargin;
    padding: 0 @inputPadding @elementPadding;
    &:last-child {
      margin-bottom: 0;
    }
  }

  & .cg-chat-survey-message {
    &:extend(.cg-chat-word-break all);

    & .cg-chat-survey-message-html {
      line-height: @defaultFontSize + 4px;
      font-size: @systemMessageFontSize;
      color: @systemMessageTextColor;
      text-align: center;
    }

    & .cg-chat-survey-message-progress-text {
      line-height: @defaultFontSize + 4px;
      font-size: @systemMessageFontSize;
      color: @systemMessageTextColor;
      text-align: center;
    }

    & .cg-chat-survey-message-progress-bar {
      line-height: @defaultFontSize + 4px;
      font-size: @systemMessageFontSize;
      color: @systemMessageTextColor;
      text-align: center;
    }
  }
}
