@import '../../layoutVariables.less';

cg-chat {
  & .cg-video-chat-standby {
    position: absolute;
    top: @windowHandleHeight;
    box-sizing: border-box;
    height: @cgChatViewHeight - @windowHandleHeight;
    padding: 0;
    background-color: #222222;
    width: 100%;
    margin: auto;
    color: white;
    display: block;
    text-align: center;
    z-index: 1050;
  }

  & #cg-video-chat-standby-heading {
    border: none;
    padding: 60px 55px 35px 55px;
    display: block;

    line-height: 1.5em;
  }

  & .cg-video-chat-standby-move-video-here-button-area {
    border: none;
    display: block;
    width: 60%;
    margin: auto;
    overflow: visible;
  }

  &.cg-chat-is-sub-window-chat {
    .cg-video-chat-standby {
      height: 100%;
    }
  }
}
