@import '../../layoutVariables.less';
@import '../../uiElements/mixins.less';

cg-chat {
  & .cg-chat-window-handle {
    .window-handle();
    cursor: move;
    z-index: @chatViewHandleZIndex;

    & .cg-chat-window-handle-title {
      display: inline-block;
      // Make sure title leaves room for the buttons
      color: @windowHandleTextColor;
      max-width: ~'calc(100% - 100px)';
      overflow: hidden;
      max-height: @windowHandleHeight;
      // padding-left: 5px;
    }

    & .cg-chat-switch-icon {
      padding: 9px 3px 0px 0px;
      float: left;
      width: 30px;
      height: 30px;
    }

    & .cg-chat-window-handle-buttons {
      box-sizing: content-box;
      line-height: @windowHandleHeight;
      height: @windowHandleHeight;
      min-height: @windowHandleHeight;
      max-height: @windowHandleHeight;
      padding-top: 5px;

      &.cg-chat-left {
        float: left;
      }

      &.cg-chat-right {
        float: right;
        padding-right: 5px;
      }

      /*
       * These buttons do not use less variables since
       * the values are specific to make the different
       * icons look good
       */

      & > button {
        position: relative;
        background: none;
        border: none;
        font-weight: bold;
        vertical-align: middle;
        padding: 0;
        padding-bottom: 2px;
        margin: 0;
        width: 22px;
        min-width: 30px;
        max-width: 30px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;
        line-height: 10px;
        cursor: pointer;
        box-sizing: border-box;
        fill: @windowHandleTextColor;
        float: left;

        &.cg-chat-window-handle-buttons-switch-to-video {
          padding-bottom: 3px;
          padding-right: 40px;
        }

        &.cg-chat-window-handle-buttons-switch-to-text {
          padding-bottom: 3px;
        }

        &.cg-chat-window-handle-buttons-switch-to-video img {
          height: 30px;
          width: 30px;
        }

        &.cg-chat-window-handle-buttons-switch-to-text img {
          height: 30px;
          width: 30px;
        }

        & svg {
          height: 20px;
          width: 20px;
        }

        &.cg-chat-window-handle-buttons-fold-in {
          margin-right: @defaultMargin;
          padding-bottom: 3px;
        }

        &.cg-chat-window-handle-buttons-minimize {
          height: 30px;
          width: 30px;

          & svg {
            transform: scaleX(0.9) scaleY(0.5) translateY(13px);
          }
        }

        &.cg-chat-window-handle-buttons-pop-out {
          height: 30px;
          width: 30px;
        }

        &.cg-chat-window-handle-buttons-leave {
          height: 30px;
          width: 30px;
          margin-right: 0;
          margin-left: 20px;

          @media (min-width: 1025px) {
            margin-left: 0;
          }
          @media (min-width: 768px) and (max-width: 1024px) {
            margin-left: 10px;
          }
        }

        &.cg-chat-window-handle-buttons-close {
          margin-right: 0;
          margin-left: 20px;

          @media (min-width: 1025px) {
            margin-left: 0;
          }
          @media (min-width: 768px) and (max-width: 1024px) {
            margin-left: 10px;
          }

          & svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }

  & .cg-chat-window-minimized .cg-chat-window {
    pointer-events: none;

    transform-origin: 50% 0%;
    animation: cg-chat-minimize-animation 0.35s ease-in-out forwards;

    & .cg-chat-window-handle {
      cursor: default;
    }
  }

  & .cg-chat-window-unminimized .cg-chat-window {
    pointer-events: none;

    transform-origin: 50% 0%;
    animation: cg-chat-unminimize-animation 0.35s ease-in-out forwards;

    & .cg-chat-window-handle {
      cursor: default;
    }
  }

  &.cg-chat-is-sub-window-chat {
    & .cg-chat-window-handle {
      cursor: default;

      & .cg-chat-window-handle-title {
        max-width: ~'calc(100% - 40px)';
      }

      & .cg-chat-window-handle-buttons-minimize,
      & .cg-chat-window-handle-buttons-pop-out {
        display: none;
      }
    }
  }

  & .is-text-chat {
    color: @textChatWindowHandleTextColor;
    background-color: @textChatWindowHandleColor !important;
  }

  & .is-video-chat {
    color: @videoChatWindowHandleTextColor;
    background-color: @videoChatWindowHandleColor !important;
    border: black;
  }

  & #cg-chat-switch-to-text {
    display: inline-block;
  }

  & #cg-chat-switch-to-text-badge {
    display: none;
  }

  & .cg-chat-unread-messages {
    & #cg-chat-switch-to-text {
      display: none;
    }

    & #cg-chat-switch-to-text-badge {
      display: inline-block;
    }
  }
}

@keyframes cg-chat-close-button-rotate {
  100% {
    transform: rotate(720deg);
  }
}

@keyframes cg-chat-minimize-animation {
  100% {
    height: 0;
    opacity: 0;
    transform: translateX(0px) scale(0.6) translateY(0px);
  }
}

@keyframes cg-chat-unminimize-animation {
  0% {
    height: 0;
    opacity: 0;
    transform: translateX(0px) scale(0.6) translateY(0px);
  }

  100% {
    opacity: 1;
    transform: translateX(0) scale(1) translateY(0);
  }
}
